
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant, { Circle } from 'vant'
import 'vant/lib/index.css'
import '@/assets/commonStyle.scss'
import VConsole from 'vconsole'
// import { h5AutoLogin } from './utils/util'
import Cookies from 'js-cookie'
if (process.env.NODE_ENV == 'testing' || process.env.NODE_ENV == 'development') {
    const vconsole = new VConsole()
}

// 默认已绑定手机号
Cookies.set('isBind', true)

// if (!Cookies.get('token')) {
//     h5AutoLogin()
// }

createApp(App).use(store).use(router).use(vant).mount('#app')
