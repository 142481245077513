
import { defineComponent, onMounted } from "vue";
import Tabbar from "@/views/tabbar/Tabbar.vue";
import { useRoute } from 'vue-router'
export default defineComponent({
  components: {
    Tabbar,
  },
  setup() {
    const route:any = useRoute()
    onMounted(() => {});
    return {
      route
    };
  },
});
