
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const active = ref(0);
    return {
      active,
    };
  },
});
