import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      showTab: true,
      title: '首页'
    }
  },
  {
    path: '/addResource',
    name: 'AddResource',
    component: () => import(/* webpackChunkName: "addResource" */ '../views/home/addResource/addResource.vue'),
    meta: {
      showTab: false,
      title: '添加资源'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: {
      showTab: false,
      title: '绑定手机号'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/user/myPage/myPage.vue'),
    meta: {
      showTab: true,
      title: '我的'
    }
  },
  {
    path: '/farmerManage',
    name: 'FarmerManage',
    component: () => import(/* webpackChunkName: "farmerManage" */ '../views/farmerManage/farmerManage.vue'),
    meta: {
      showTab: true,
      title: '农户管理'
    }
  },
  {
    path: '/farmingDetail/:id',
    name: 'FarmingDetail',
    meta: {
      showTab: false,
      title: '农事详情'
    },
    component: () => import(/* webpackChunkName: "farmingDetail" */ '../views/home/farmingDetail/farmingDetail.vue')
  },
  {
    path: '/farmingRecord',
    name: 'FarmingRecord',
    meta: {
      showTab: false,
      title: '农事记录'
    },
    component: () => import(/* webpackChunkName: "farmingRecord" */ '../views/home/farmingRecord/farmingRecord.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// 路由拦截
router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string
  next()
})

export default router
